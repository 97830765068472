import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css';
import { generateMerkleProof } from './whitelist/merkle-tree'
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'



function App() {

	const [feedback, setFeedback] = useState(``)

	const dispatch = useDispatch()
	const blockchain = useSelector((state) => state.blockchain)
	const data = useSelector((state) => state.data)
	const [claimingNft, setClaimingNft] = useState(false)
	const [mintAmount, setMintAmount] = useState(1)
	const [CONFIG, SET_CONFIG] = useState({
		NFTNAME: "HYPERBATCH BARISTA CLUB",
		CONTRACT_ADDRESS: "0x1E2eE24204600fc749388214E6974CfBA5Bc259b",
		NETWORK: "Goerli",
		ID: 5,
		MARKETPLACE: "Opeansea",
		TWITTER: "https://twitter.com/HyperBatch",
		ETHERSCAN: "https://goerli.etherscan.io/address/0x1E2eE24204600fc749388214E6974CfBA5Bc259b",
		DISCORD: "https://discord.gg/YWEWpnvtb4",
		MARKETPLACE_LINK: "https://opensea.io"
	})
	var loaderTxt = ``;
	var supplyLimit;
	var price;
	var MintCount;
	var maxLimitPerWallet;
	var maxMintAmountPerTx;
	var saleStatus;
	var button;

	if ((data.maxSupply) == 1 && blockchain.smartContract !== null) {
		loaderTxt = <React.Fragment>
			{'Loading please wait'} {" "}
			<FontAwesomeIcon icon={faSpinner} />
		</React.Fragment>
	}

	if (Number(data.totalSupply) >= Number(data.maxSupply)) {
		button = <React.Fragment>
			<a href={CONFIG.MARKETPLACE_LINK} target={'_blank'}> <button className="flex justify-center items-center w-full py-3.5 rounded-lg bg-indigoblue 
				  hover:bg-indigoblue-dark text-navy font-bold text-sm duration-300">
				<span className="mr-3"></span>
				Soldout! Checkout {CONFIG.MARKETPLACE}
			</button> </a>
		</React.Fragment>
	}
	else if ((data.wlsaleStatus) == true) {
		button = <React.Fragment>
			<button className="flex justify-center items-center w-full py-3.5 rounded-lg bg-indigoblue 
		 hover:bg-indigoblue-dark text-navy font-bold text-sm duration-300"
				disabled={claimingNft ? 1 : 0}
				onClick={(e) => {
					if ((data.wlsaleStatus) == true) {
						if (Number(data.totalSupply) + mintAmount <= Number(data.wlsupplyLimit)) {
							const merkleProof = generateMerkleProof(blockchain.account);
							if (merkleProof.length > 0) {
								if (((Number(data.wlMintCount)) + Number(mintAmount)) <= (Number(data.wlmaxLimitPerWallet))) {
									WlclaimNFTs()
								}
								else {
									setFeedback('Max Mint Limit Reached or Minting More Than Allowed!')
								}
							}
							else{
							setFeedback('Wallet Address not Whitelisted!')}
						}
						else {
							setFeedback('Whitelist Supply Soldout!')
						}
					}
					else {
						setFeedback('Sale not Active!')
					}
					getData()
				}}
			>
				<span className="mr-3"></span>
				{claimingNft ? 'BUSY' : 'WHITELIST MINT'}
			</button>
		</React.Fragment>
	}
	else {
		button = <React.Fragment>
			<button
				disabled={claimingNft ? 1 : 0}
				onClick={(e) => {
					if ((data.publicSaleStatus) == true) {
						if (Number(data.totalSupply) + mintAmount <= Number(data.publicsupplyLimit)) {

							if (((Number(data.publicMintCount)) + Number(mintAmount)) <= (Number(data.publicmaxLimitPerWallet))) {
								PublicclaimNFTs()
							}
							else {
								setFeedback('Max Mint Limit Reached or Minting More Than Allowed!')
							}
						}
						else {
							setFeedback('Public Supply Reached')
						}
					}
					else {
						setFeedback('Sale not Active')
					}
					getData()
				}}
				className="flex justify-center items-center w-full py-3.5 rounded-lg bg-indigoblue 
			  hover:bg-indigoblue-dark text-navy font-bold text-sm duration-300">
				<span className="mr-3"></span>
				{claimingNft ? 'BUSY' : 'MINT'}
			</button>
		</React.Fragment>
	}

	const getData = () => {
		if (blockchain.account !== '' && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account))
		}
	}

	useEffect(() => {
		getData((data.totalSupply))
	}, [blockchain.account])

	const getConfig = async () => {
		const configResponse = await fetch('/config/config.json', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		})
		const config = await configResponse.json()
		SET_CONFIG(config)
	}

	useEffect(() => {
		getConfig()
	}, [])

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1
		if (newMintAmount < 1) {
			newMintAmount = 1
		}
		setMintAmount(newMintAmount)
	}

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1
		if (newMintAmount > maxMintAmountPerTx) {
			newMintAmount = maxMintAmountPerTx
		}
		setMintAmount(newMintAmount)
	}

	if ((data.wlsaleStatus) == true) {
		supplyLimit = (data.wlsupplyLimit);
		price = (data.wlprice);
		MintCount = (data.wlMintCount);
		maxLimitPerWallet = (data.wlmaxLimitPerWallet);
		maxMintAmountPerTx = (data.wlmaxMintAmountPerTx);
		saleStatus = (data.wlsaleStatus);
	}
	else {
		supplyLimit = (data.publicsupplyLimit);
		price = (data.publicprice);
		MintCount = (data.publicMintCount);
		maxLimitPerWallet = (data.publicmaxLimitPerWallet);
		maxMintAmountPerTx = (data.publicmaxMintAmountPerTx);
		saleStatus = (data.publicSaleStatus);
	}

	const genProof = () => {
		var address = document.getElementById("address").value;
		const merkleProof = generateMerkleProof(address).toString();
		console.log(merkleProof)
		if (merkleProof.length < 1) {
			setFeedback(`Wallet Address not Whitelisted!`)
		}
		else {
			navigator.clipboard.writeText(merkleProof);
			setFeedback(`Copied!`)
		}
	}
	const WlclaimNFTs = () => {
		let cost = (data.wlprice)
		let totalCostWei = String((data.wlprice) * mintAmount)
		console.log('Cost: ', totalCostWei)
		console.log(cost)
		setFeedback(`Minting Your ${CONFIG.NFTNAME}...`)
		setClaimingNft(true)
		const merkleProof = generateMerkleProof(blockchain.account);
		blockchain.smartContract.methods
			.WhitelistMint(mintAmount, merkleProof)
			.send({
				gasLimit: 210000,
				maxPriorityFeePerGas: null,
				maxFeePerGas: null,
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once('error', (err) => {
				console.log(err)
				setFeedback(err.message)
				setClaimingNft(false)
			})
			.then((receipt) => {
				console.log(receipt)
				setFeedback(
					`The ${CONFIG.NFTNAME} is Yours! Visit ${CONFIG.MARKETPLACE} to View It.`,
				)
				setClaimingNft(false)
				dispatch(fetchData(blockchain.account))
			})
	}

	const PublicclaimNFTs = () => {
		let cost = (data.publicprice)
		let totalCostWei = String(cost * mintAmount)
		console.log('Cost: ', totalCostWei)
		console.log(cost)
		setFeedback(`Minting Your ${CONFIG.NFTNAME}...`)
		setClaimingNft(true)
		blockchain.smartContract.methods
			.PublicMint(mintAmount)
			.send({
				gasLimit: 210000,
				maxPriorityFeePerGas: null,
				maxFeePerGas: null,
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once('error', (err) => {
				console.log(err)
				setFeedback(err.message)
				setClaimingNft(false)
			})
			.then((receipt) => {
				console.log(receipt)
				setFeedback(
					`The ${CONFIG.NFTNAME} is Yours! Visit ${CONFIG.MARKETPLACE} to View It.`,
				)
				setClaimingNft(false)
				dispatch(fetchData(blockchain.account))
			})
	}



	return (
		<main className="flex items-center justify-center p-4 App">
			<div className="bg-navy rounded-[0.625rem] overflow-hidden max-w-sm 
			 md:max-w-[57.5rem] md:flex anim-zoomIn">
				<picture className="h-auto basis-2/4 block md:h-auto overflow-hidden">
					<source
						media="(min-width: 768px)"
						srcSet="images/sample.png" />
					<img
						className="w-full h-full"
						src="images/sample.png"
						alt="Sample Image" />
				</picture>
				<div className="p-6 basis-2/4 md:p-8 anim-fadeIn">
					<div className="text-xs tracking-[0.3125rem] text-blue-grayish">HYPERBATCH GENESIS NFT</div>
					<h1 className="font-bold text-blue-dark text-[2rem] 
				  leading-8 mt-3 mb-4 md:mt-5 md:mb-6">
						MINT YOUR {CONFIG.NFTNAME} NFT
					</h1>
					<p className="text-sm leading-6 text-blue-grayish">
						Hyperbatch Barista Club is the genesis NFT collection of 10K original artworks by Adam Liu. The barista characters originate from two baseball caps stacked up together hinting at a mega trend in society that people are wearing multiple hats at the same time and the unique combinations of hats are giving us unique identities. All the traits are hand-drawn and rendered with three colour palettes representing appearances, feelings, and dreams. Hyperbatch Barista Club is your membership pass to Hyperbatch and Hyper Café ecosystem.</p>
					<div className="mt-6 mb-5 flex items-center md:my-5">
						<span className="font-bold text-[2rem] text-indigoblue mr-6 slide-up ">Ξ{price / 1e18}</span>
						<span className="text-[0.8125rem] text-blue-grayish">{loaderTxt}</span>
					</div>
					<div className="my-3">
						<div className="flex border border-navy-300 text-navy-400 divide-x divide-navy-100 w-max">
							<button disabled={claimingNft ? 1 : 0}
								onClick={(e) => {
									e.preventDefault()
									decrementMintAmount()
								}} className="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none">-</button>
							<div className="h-8 w-8 text-base flex items-center justify-center">{mintAmount}</div>
							<button disabled={claimingNft ? 1 : 0}
								onClick={(e) => {
									e.preventDefault()
									incrementMintAmount()
								}} className="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none">+</button>
						</div>
					</div>

					< p className="text-sm leading-6 text-indigoblue "> {feedback}</p>
					{blockchain.account === '' ||
						blockchain.smartContract === null ? (<button onClick={(e) => {
							e.preventDefault()
							dispatch(connect())
							getData()
						}} className="flex justify-center items-center w-full py-3.5 rounded-lg bg-indigoblue 
				  hover:bg-indigoblue-dark text-navy font-bold text-sm duration-300 ">
							<span className="mr-3"></span>
							Connect
						</button>) : (<>
							{button}
						</>)}
					< p className="text-sm leading-6 text-center text-navy-400"> Total Minted: {data.totalSupply} | Max Supply: {data.maxSupply} | Wallet Limit: {maxLimitPerWallet}  </p>
					<br />
					<div className="flex flex-row space-x-4 place-content-center">
						<a href={CONFIG.ETHERSCAN} target={"_blank"}>	<img className="w-8 h-8 visible" src="icons/etherscan.png" /></a>
						<a href={CONFIG.MARKETPLACE_LINK} target={"_blank"}>	<img className="w-8 h-8 visible" src="icons/opensea.png" /></a>
						<a href={CONFIG.DISCORD} target={"_blank"}>	<img className="w-8 h-8 visible" src="icons/discord.png" /></a>
						<a href={CONFIG.TWITTER} target={"_blank"}>	<img className="w-8 h-8 visible" src="icons/twitter.png" /></a>
					</div>
				</div>
			</div>
		</main >
	);
}

export default App;