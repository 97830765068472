// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      //
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
       let maxSupply = await store
         .getState()
        .blockchain.smartContract.methods.supplyLimit()
         .call();
         let account = await store
         .getState()
         .blockchain.account;  

      //   
      let wlsupplyLimit = await store
      .getState()
     .blockchain.smartContract.methods.supplyLimit()
      .call();
      let wlprice = await store
      .getState()
     .blockchain.smartContract.methods.wlprice()
      .call();
      let wlMintCount = await store
      .getState()
     .blockchain.smartContract.methods.wlMintCount(account)
      .call();
      let wlmaxLimitPerWallet = await store
      .getState()
     .blockchain.smartContract.methods.wlmaxLimitPerWallet()
      .call();
      let wlmaxMintAmountPerTx = await store
      .getState()
     .blockchain.smartContract.methods.wlmaxMintAmountPerTx()
      .call();
      let wlsaleStatus = await store
      .getState()
     .blockchain.smartContract.methods.whitelistSale()
      .call();

       //   
       let publicsupplyLimit = await store
       .getState()
      .blockchain.smartContract.methods.supplyLimit()
       .call();
       let publicprice = await store
       .getState()
      .blockchain.smartContract.methods.publicprice()
       .call();
       let publicMintCount = await store
       .getState()
      .blockchain.smartContract.methods.publicMintCount(account)
       .call();
       let publicmaxLimitPerWallet = await store
       .getState()
      .blockchain.smartContract.methods.publicmaxLimitPerWallet()
       .call();
       let publicmaxMintAmountPerTx = await store
       .getState()
      .blockchain.smartContract.methods.publicmaxMintAmountPerTx()
       .call();
       let publicSaleStatus = await store
       .getState()
      .blockchain.smartContract.methods.publicSale()
       .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          maxSupply,
          //

          wlsupplyLimit,
          wlprice,
          wlMintCount,
          wlmaxLimitPerWallet,
          wlmaxMintAmountPerTx,
          wlsaleStatus,

          //

          publicsupplyLimit,
          publicprice,
          publicMintCount,
          publicmaxLimitPerWallet,
          publicmaxMintAmountPerTx,
          publicSaleStatus,

        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
